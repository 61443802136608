import React from "react";
import "../Styles/Header.css";
// import brasaopr from "../imagens/Logo-Parana.png";
import brasaopr from "../imagens/marca_Simples_entes.png";

function Footer() {
  return (
    <footer className="myPageFooter">
      <div className="contentfooter">

        <div className="footerversao">
          <div className="header-altura-conteudo2">
            {/* <span className="x26g">Estado do Paraná</span> */}
            <span className="x26b">Versão: 3.14.1</span>
          </div>
        </div>

        <div className="divLogo logosimples">
          {/* <div className="childre1">
            <div className="containerLogoHeader">
              <div className="divIconLogo">
                <img src={brasaopr} alt="menu" />
              </div>
            </div>
          </div>
          <div className="childre2">
          </div> */}
          <div className="">
            <img src={brasaopr} alt="menu" />
          </div>
        </div>

      </div>
    </footer>
  );
}

export default Footer;
