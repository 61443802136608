import React from "react";
import "../Styles/Header.css";
// import brasaopr from "../imagens/Logo-Parana.png";
import brasaopr from "../imagens/logo-simples.png";

function Header() {
  return (
    <header className="header">
      <div className="contentheader">

        <div className="divLogo">
          <div className="containerLogoHeader">
           <div className="divIconLogo">
            <img src={brasaopr} alt="menu" />
            <span className="textpgmei">PGMEI</span>
           </div>
          </div>
          <div className="header-altura-conteudo">
            {/* <span className="x26g">Estado do Paraná</span> */}
            <span className="x26a">Programa Gerador de DAS do Microempreendedor Individual</span>
          </div>
        </div>

        <div className="divLogo dvl2">
          <div className="childre1">
           {/* <span className="x17w">Ir para o Portal da Fazenda</span> */}
          </div>
          <div className="childre2">
            {/* <span className="xf2">Acessar o sistema</span> */}
          </div>
        </div>

      </div>
    </header>
  );
}

export default Header;
